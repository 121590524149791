/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Divider, Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Inventory, LocalActivity, LocalMall, Money, QrCode2, ShoppingCart, Star} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";


const Formulario_ver_ganador = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={QrCode2} nombre={'Id de subasta'} dato={'idSubasta'}  {...props}
                                  requerido={'El id es requerido'} editable={false}

                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={LocalMall} nombre={'Valor compra'} dato={'compra'}  {...props}
                                   requerido={'El valor de compra es requerido'}
                                   editable={false}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory} nombre={'Nombre de producto'} dato={'nombre'}  {...props}
                                  requerido={'El nombre es requerido'}
                                  editable={false}

                    />
                </Grid>


                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={Money} nombre={'Valor a recaudar'} dato={'recaudo'}  {...props}
                                   requerido={'El valor a recaudar de venta es requerido'}
                                   editable={false}

                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={ShoppingCart} nombre={'Valor minimo de venta'} dato={'minimo'}  {...props}
                                   requerido={'El valor de compra es requerido'}
                                   editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Divider sx={{width: '100%'}}/>
                </Grid>


                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={LocalActivity} nombre={'Numero de tickets'} dato={'numTickets'}  {...props}
                                  requerido={'El numero de tickets es requerido'}
                                  editable={false}

                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Star} nombre={'Numero Participaciones'} dato={'numParticipaciones'}  {...props}
                                  requerido={'El numero de tickets es requerido'}
                                  editable={false}

                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_ver_ganador