/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {LocalActivity, ManageSearch, PointOfSale} from "@mui/icons-material";
import {where} from "firebase/firestore";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import Dialogo_Tickets_Crear from "../Dialogos/Dialogo_Tickets_Crear";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {contarPropiedadSumaWheres} from "../../Servicios/BD/Contadores/contarPropiedadSumaWheres";
import {useObtenerColleccionTablaWhere} from "../../Servicios/BD/useObtenerColleccionTablaWhere";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import {obtenerColWheres} from "../../Servicios/BD/obtenerColWheres";
import {actualizarDocsMultiples} from "../../Servicios/BD/actualizarDocsMultiples";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo.svg'

const Seccion_Caja = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numVentasHoy, setNumVentasHoy] = useState(0)
    const [numVentas, setNumVentas] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const [valoresInidicadores, setValoresInidicadores] = useState([])
    const [wheres, setWheres] = useState([where('estado', '==', 'Nuevo')])
    const {props, cargarNuevamente} = useObtenerColleccionTablaWhere({
        coleccion: 'ventasTickets',
        filtroInicial: 'fecha',
        dirInicial: 'desc',
        wheres: wheres
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Tickets_Crear,
        realizado: () => setRecargar(!recargar),
        titulo: 'Creando Tickets'
    })
    const {abrirCargador, cerrarCargador, Cargador} = useLoaders({logo: logo})


    const BotonesTitulo = [
        <Button
            startIcon={<PointOfSale/>}
            onClick={() => realizarCuadre()}
        >
            Hacer cuadre
        </Button>
    ]

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'detalles' :
                abrir(row)
                break

        }

    }

    const realizarCuadre = () => {

        let wheres = [where('estado', '==', 'Nuevo')]
        abrirCargador('Realizando cuadre de caja')
        obtenerColWheres({col: 'ventasTickets', wheres: wheres}).then((dox) => {
            if (dox.res) {
                let ventas = dox.data
                actualizarDocsMultiples({
                    col: 'ventasTickets',
                    arrObjs: ventas,
                    datos: {estado: 'Entregado'}
                }).then((des) => {
                    if (des.res) {
                        alert('Caja entregada')
                        setRecargar(!recargar)
                        cerrarCargador()
                    }
                })
            }
        })

    }
    const cargarIndicadores = () => {

        let wheresEfectivo = [where('estado', '==', 'Nuevo'), where('tipo', '==', 'Efectivo')]
        let valorEfectivo = 0
        let valorBancolombia = 0
        let valorDavivienda = 0
        let valorNequi = 0
        contarPropiedadSumaWheres({
            coleccion: 'ventasTickets',
            wheres: wheresEfectivo,
            propiedad: 'valor'
        }).then((dox) => {
            if (dox.res) {
                valorEfectivo = dox.data
                let wheresBancolombia = [where('estado', '==', 'Nuevo'), where('tipo', '==', 'Trans. Bancolombia')]
                contarPropiedadSumaWheres({
                    coleccion: 'ventasTickets',
                    wheres: wheresBancolombia,
                    propiedad: 'valor'
                }).then((des) => {
                    if (des.res) {
                        valorBancolombia = des.data
                        let wheresDavivienda = [where('estado', '==', 'Nuevo'), where('tipo', '==', 'Trans. Davivienda')]
                        contarPropiedadSumaWheres({
                            coleccion: 'ventasTickets',
                            wheres: wheresDavivienda,
                            propiedad: 'valor'
                        }).then((dav) => {
                            if (dav.res) {
                                valorDavivienda = dav.data
                                let wheresNequi = [where('estado', '==', 'Nuevo'), where('tipo', '==', 'Trans. Nequi')]
                                contarPropiedadSumaWheres({
                                    coleccion: 'ventasTickets',
                                    wheres: wheresNequi,
                                    propiedad: 'valor'
                                }).then((neq) => {
                                    if (neq.res) {
                                        valorNequi = neq.data

                                        crearIndicadores(valorEfectivo, valorNequi, valorBancolombia, valorDavivienda)


                                    }
                                })


                            }
                        })

                    }
                })


            }
        })
    }
    const crearIndicadores = (efectivo, nequi, bancolombia, davivienda) => {


        let indicadorEfectivo = adaptador_indicador({
            titulo: 'Dinero en efectivo',
            valorActual: formatoMoneda(efectivo),
            valorAnterior: '',
            tipo: 'nomoneda'
        })

        let indicadorNequi = adaptador_indicador({
            titulo: 'Dinero en nequi',
            valorActual: formatoMoneda(nequi),
            valorAnterior: '',
            tipo: 'nomoneda'
        })

        let indicadorBancolombia = adaptador_indicador({
            titulo: 'Dinero en Bancolombia',
            valorActual: formatoMoneda(bancolombia),
            valorAnterior: '',
            tipo: 'nomoneda'
        })

        let indicadorDavivienda = adaptador_indicador({
            titulo: 'Dinero en Davivienda',
            valorActual: formatoMoneda(davivienda),
            valorAnterior: '',
            tipo: 'nomoneda'
        })

        setValoresInidicadores([indicadorEfectivo, indicadorNequi, indicadorBancolombia, indicadorDavivienda])

    }


    useEffect(() => {


        let wheres = [where('estado', '==', 'Nuevo')]
        contarPropiedadSumaWheres({coleccion: 'ventasTickets', wheres: wheres, propiedad: 'valor'}).then((dox) => {
            console.log(dox)
            if (dox.res) {
                setNumVentasHoy(dox.data)
            }
        })

        contarColeccion({coleccion: 'ventasTickets', wheres}).then((dox) => {
            if (dox.res) {
                setNumVentas(dox.data)
            }
        })

        cargarIndicadores()

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Cargador/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Caja'} textoComplementario={'En ventas sin entregar'}
                                      textoResaltado={`${formatoMoneda(numVentasHoy)}`} botones={BotonesTitulo}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Indicadores valores={valoresInidicadores} dir={'flex-end'}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numVentas}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Caja

const filtros = [
    {
        nombre: 'Tipo',
        propiedad: 'tipo',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Paquete',
        direccion: 'left',
        propiedad: 'paquete',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Nick Usuario',
        direccion: 'left',
        propiedad: 'nick',

    },
    {
        nombre: 'Tipo',
        direccion: 'left',
        propiedad: 'tipo',

    },

    {
        nombre: 'Valor',
        direccion: 'right',
        propiedad: 'valor',
        tipo: 'moneda'

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Paquete',
        direccion: 'left',
        propiedad: 'nick',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Valor',
        direccion: 'right',
        propiedad: 'tipo',

    }
]

const botonesTabla = [
    {
        nombre: 'Detalles',
        icono: ManageSearch,
        id: 'detalles'
    }

]



