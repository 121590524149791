/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOSPOTENCIALES} from "../../Constantes";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import {CurrencyExchange, Edit, Gavel, LocalActivity, Star, TaskAlt} from "@mui/icons-material";
import {contarPropiedadSumaWheres} from "../../Servicios/BD/Contadores/contarPropiedadSumaWheres";
import {where} from "firebase/firestore";
import Dialogo_producto_subasta from "../Dialogos/Dialogo_producto_subasta";
import Dialogo_valores_subasta from "../Dialogos/Dialogo_valores_subasta";
import Dialogo_tickes_subasta from "../Dialogos/Dialogo_tickes_subasta";
import Dialogo_aprobar_subasta from "../Dialogos/Dialogo_aprobar_subasta";
import Dialogo_ver_Ganador from "../Dialogos/Dialogo_ver_Ganador";

const Seccion_Subastas = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'subastas',
        filtroInicial: 'fecha',
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_producto_subasta,
        realizado: () => setRecargar(!recargar),
        titulo: 'Producto de subasta'
    })
    const {Dialogo: DialogoValores, abrir: abrirValores} = useDialogo({
        Componente: Dialogo_valores_subasta,
        realizado: () => setRecargar(!recargar),
        titulo: 'Valores de subasta'
    })

    const {Dialogo: DialogoTicket, abrir: abrirTicket} = useDialogo({
        Componente: Dialogo_tickes_subasta,
        realizado: () => setRecargar(!recargar),
        titulo: 'Tickets de subasta'
    })
    const {Dialogo: DialogoAprobar, abrir: abrirAprobar} = useDialogo({
        Componente: Dialogo_aprobar_subasta,
        realizado: () => setRecargar(!recargar),
        titulo: 'Tickets de subasta'
    })
    const {Dialogo: DialogoGanador, abrir: abrirGanador} = useDialogo({
        Componente: Dialogo_ver_Ganador,
        realizado: () => setRecargar(!recargar),
        titulo: 'Ver Ganador'
    })
    const [valoresPedidos, setValoresPedidos] = useState([])


    const llenarValores = () => {

        contarPropiedadSumaWheres({
            coleccion: 'subastas',
            propiedad: 'numTickets',
            wheres: [where('estado', '==', 'Activa')]
        }).then((dox) => {
            if (dox.res) {

                let numTotalTickets = dox.data

                let potencialRecaudo = adaptador_indicador({
                    titulo: 'Participaciones Disp.',
                    valorAnterior: '',
                    valorActual: numTotalTickets,
                    detalle: '',
                    tipo: 'nomoneda'
                })

                setValoresPedidos([potencialRecaudo])
            }
        })

    }

    const BotonesTitulo = [
        <Button
            startIcon={<Gavel/>}
            onClick={() => abrir()}
        >
            Nueva
        </Button>
    ]

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'editar' :
                abrir(row)
                break
            case 'valores' :
                abrirValores(row)
                break
            case 'tickets' :
                abrirTicket(row)
                break
            case 'aprobar' :
                abrirAprobar(row)
                break
            case 'ganador' :
                abrirGanador(row)
                break
        }

    }


    useEffect(() => {

        contarPropiedad({coleccion: 'subastas', propiedad: 'estado', valor: 'Activa'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })

        llenarValores()

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoValores/>

            <DialogoTicket/>

            <DialogoAprobar/>

            <DialogoGanador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Subastas'} textoComplementario={'activas'}
                                      textoResaltado={`${numProductos} Subastas`} botones={BotonesTitulo}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Indicadores valores={valoresPedidos} dir={'flex-end'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 0}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numProductos}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Subastas

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Producto',
        direccion: 'left',
        propiedad: 'nombre',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Id Subasta',
        direccion: 'left',
        propiedad: 'idSubasta',

    },
    {
        nombre: 'Part.',
        direccion: 'left',
        propiedad: 'participaciones',
        tipo: 'numArray'

    },
    {
        nombre: 'Total Tickets',
        direccion: 'left',
        propiedad: 'numTickets',

    },

    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    }
]

const botonesTabla = [
    {
        nombre: 'Editar',
        icono: Edit,
        id: 'editar'
    },
    {
        nombre: 'Valores',
        icono: CurrencyExchange,
        id: 'valores'
    },
    {
        nombre: 'Tickets',
        icono: LocalActivity,
        id: 'tickets'
    },
    {
        nombre: 'Aprobar',
        icono: TaskAlt,
        id: 'aprobar'
    },
    {
        nombre: 'Ver Ganador',
        icono: Star,
        id: 'ganador'
    },

]



