/**************************************************
 * Nombre:       Dialogo_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {GRADIENTE} from "../../Constantes";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import Formulario_Subasta from "../Formularios/Formulario_Subasta";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {generarContrato} from "../ContratoInteligente/generarContrato";
import Formulario_subasta_producto from "../Formularios/Formulario_subasta_producto";
import Formulario_valores_subasta from "../Formularios/Formulario_valores_subasta";
import Formulario_tickets_subasta from "../Formularios/Formulario_tickets_subasta";
import {useEffect, useState} from "react";
import {obtenterNumTikets} from "../../Utilidades/obtenterNumTikets";
import {obtenerValorMaximo} from "../../Utilidades/obtenerValorMaximo";

const Dialogo_tickes_subasta = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})
    const [sugerencia, setSugerencia] = useState(0)

    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando cambios')
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.estado = 'Inactiva'

            if (parseInt(obj.numTickets) <= sugerencia) {
                alert('El valor de la venta total de los tickets es menor al valor de compra del producto')
                cerrarCargador()
            } else {

                let valorMaximo = obtenerValorMaximo({valorMinimo: obj.minimo, numeroTickets: obj.numTickets})
                obj.maximo = valorMaximo
                subirSubasta(obj)
            }


        })
    }


    const subirSubasta = (ent) => {
        let obj = ent

        subirImagenFile(ent.img, 'productos').then((dox) => {

            if (dox.res) {
                obj.img = dox.data

                subirVariasImagenFile(ent.imgs, 'productos').then((des) => {
                    if (des.res) {
                        obj.imgs = des.data


                        guardarDoc('subastas', obj).then((des) => {
                            if (des.res) {
                                cerrar()
                                cerrarCargador()
                                realizado()
                                alert('Cambios realizados con exito')
                            }
                        })

                    }
                })

            }
        })


    }


    useEffect(() => {

        if (datos) {

            setSugerencia(obtenterNumTikets({valorRecaudar: datos.recaudo}))
        }

    }, [datos]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 2}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_tickets_subasta props={props} sugerencia={sugerencia}
                                            editable={datos.estado === 'Inactiva'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => guardarDatos()}
                    sx={{px: 8, pt: 1, pb: 1.1, background: GRADIENTE}}>Guardar Cambios</Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_tickes_subasta