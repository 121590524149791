export const crearValoresSorteo = (entidad) => {

    let valMinimo = entidad.minimo
    let numTickets = entidad.numTickets

    let arr = []
    for (let i = 0; i <= numTickets - 1; i++) {
        let valor = valMinimo + (i * 50)
        arr.push(valor)
    }

    return arr


}