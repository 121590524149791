/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOVENTATICKETS} from "../../Constantes";
import {EmojiEmotions, ManageSearch, Receipt} from "@mui/icons-material";
import {where} from "firebase/firestore";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import Dialogo_Tickets_Crear from "../Dialogos/Dialogo_Tickets_Crear";
import {contarColeccionWhere} from "../../Servicios/BD/Contadores/contarColeccionWhere";
import Dialogo_ticket_detalle from "../Dialogos/Dialogo_ticket_detalle";
import {useObtenerColleccionTablaWhere} from "../../Servicios/BD/useObtenerColleccionTablaWhere";
import Dialogo_usuario_detalle from "../Dialogos/Dialogo_usuario_detalle";
import Dialogo_avatar_detalle from "../Dialogos/Dialogo_avatar_detalle";

const Seccion_Avatares = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numAvatares, setNumAvatares] = useState(0)
    const [numTickets, setNumTickets] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'avatares',
        filtroInicial: 'fecha',
        dirInicial: 'desc',
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_avatar_detalle,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle del Avatar'
    })


    const BotonesTitulo = [
        <Button
            onClick={() => abrir()}
            startIcon={<EmojiEmotions/>}>Nuevo Avatar</Button>
    ]

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'detalles' :
                abrir(row)
                break

        }

    }


    useEffect(() => {


        contarColeccion({coleccion: 'avatares'}).then((dox) => {
            if (dox.res) {
                setNumAvatares(dox.data)
            }
        })


        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Avatares'} textoComplementario={''}
                                      textoResaltado={`${numAvatares} avatares en lista`} botones={BotonesTitulo}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numAvatares}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Avatares

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',
    },
    {
        nombre: 'Tipo',
        direccion: 'rigth',
        propiedad: 'tipo',
    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha - ID',
        direccion: 'left',
        propiedad: 'id',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Asignado a',
        direccion: 'left',
        propiedad: 'nick',

    },
    {
        nombre: 'ID Subasta',
        direccion: 'right',
        propiedad: 'idSubasta',

    }
]

const botonesTabla = [
    {
        nombre: 'Detalles',
        icono: ManageSearch,
        id: 'detalles'
    }

]



