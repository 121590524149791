/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOVENTATICKETS} from "../../Constantes";
import {ManageSearch, Receipt} from "@mui/icons-material";
import {where} from "firebase/firestore";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import Dialogo_Tickets_Crear from "../Dialogos/Dialogo_Tickets_Crear";
import {contarColeccionWhere} from "../../Servicios/BD/Contadores/contarColeccionWhere";
import Dialogo_ticket_detalle from "../Dialogos/Dialogo_ticket_detalle";

const Seccion_Tickets = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numTicketsLibres, setNumTicketsLibres] = useState(0)
    const [numTickets, setNumTickets] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'tickets',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_ticket_detalle,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle del Ticket'
    })


    const BotonesTitulo = []

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'detalles' :
                abrir(row)
                break

        }

    }


    useEffect(() => {


        let wheres = [where('idSubasta', '==', 'none')]
        contarColeccionWhere({coleccion: 'tickets', wheres: wheres}).then((dox) => {
            if (dox.res) {
                setNumTicketsLibres(dox.data)
            }
        })

        contarColeccion({coleccion: 'tickets', wheres}).then((dox) => {
            if (dox.res) {
                setNumTickets(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Tickets'} textoComplementario={'sin participar'}
                                      textoResaltado={`${numTicketsLibres} tickets`} botones={BotonesTitulo}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numTickets}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Tickets

const filtros = [
    {
        nombre: 'Usuario',
        propiedad: 'nick',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha - ID',
        direccion: 'left',
        propiedad: 'id',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Asignado a',
        direccion: 'left',
        propiedad: 'nick',

    },
    {
        nombre: 'ID Subasta',
        direccion: 'right',
        propiedad: 'idSubasta',

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha - ID',
        direccion: 'left',
        propiedad: 'id',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Asignado a',
        direccion: 'left',
        propiedad: 'nick',

    },
    {
        nombre: 'ID Subasta',
        direccion: 'right',
        propiedad: 'idSubasta',

    }
]

const botonesTabla = [
    {
        nombre: 'Detalles',
        icono: ManageSearch,
        id: 'detalles'
    }

]



