export const adaptador_contrato_publico = (valoresContrato) =>{

    let obj = {
        ...valoresContrato
    }

    obj.valorBeneficiado = 'xxxxxxxxx'
    obj.posicionValorBeneficiado = 'xxxx'
    obj.numerosValidos = ['xxxx','xxxx','xxxx','xxxx','xxxx','xxxx','xxxx','xxxx','xxxx','xxxx','xxxx']
    obj.numerosRandom = ['xxxxx','xxxxx','xxxxx','xxxxx','xxxxx','xxxxx','xxxxx','xxxxx','xxxxx','xxxxx','xxxxx']

    return obj
}