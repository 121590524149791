/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AccountBalance, Badge, LocalOffer, Subtitles} from "@mui/icons-material";
import {PAQUETES, TIPOTRANSACCION} from "../../Constantes";
import {useEffect, useState} from "react";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import IngresoSelectPropId from "../../Modulo_formularios/Componentes/IngresoSelectPropId";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";

const Formulario_tickets_crear = ({...props}) => {
    const [estado, setEstado] = useState('')
    const [paquete, setPaquete] = useState('')
    const [usuarios, setUsuarios] = useState([])


    useEffect(() => {

        let q = query(collection(fire, 'usuarios'),
            where('nick', '>', ''))
        obtenerColeccionQuery(q).then((dox) => {
            if (dox.res) {
                setUsuarios(dox.data)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={AccountBalance} nombre={'Tipo Transaccion'} dato={'tipo'}  {...props}
                                   opciones={TIPOTRANSACCION}
                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Subtitles} nombre={'Codigo de transferencia'} dato={'codigo'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectPropId Icono={LocalOffer} nombre={'Paquete adquirido'} dato={'paquete'}  {...props}
                                         opciones={PAQUETES} propiedad={'nombre'} propiedadId={'index'} datoId={'index'}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelectPropId Icono={Badge} nombre={'Asignando a'} dato={'nick'}  {...props}
                                         datoId={'idUsuario'} opciones={usuarios} propiedad={'nick'} propiedadId={'id'}

                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_tickets_crear