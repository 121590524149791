/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import Dialogo_Potenciales from "../Dialogos/Dialogo_Potenciales";
import {ESTADOSPOTENCIALES} from "../../Constantes";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import Indicadores from "../../Modulo_Graficas/Componentes/Indicadores/Indicadores";
import {adaptador_indicador} from "../../Modulo_Graficas/Adaptadores/adaptador_indicador";
import {CloudDownload, Edit} from "@mui/icons-material";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import {LoadingButton} from "@mui/lab";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";

const Seccion_Potenciales = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'potenciales',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({Componente: Dialogo_Potenciales, realizado: () => setRecargar(!recargar)})
    const [valoresPedidos, setValoresPedidos] = useState([])
    const [cargando, setCargando] = useState(false)


    const llenarValores = () => {

        contarColeccion({coleccion: 'potenciales'}).then((dox) => {
            if (dox.res) {
                let valores = adaptador_indicador({
                    titulo: 'Potenciales totales',
                    valorAnterior: '',
                    valorActual: dox.data,
                    detalle: '',
                    tipo: 'nomoneda'
                })

                setValoresPedidos([valores])
            }
        })

    }

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'editar' :
                abrir(row)
                break
        }

    }

    const exportarDatos = () => {
        setCargando(true)
        obtenerCol('potenciales').then((dox) => {
            if (dox.res) {
                let arr = dox.data
                const worksheet = XLSX.utils.json_to_sheet(arr);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

                // Buffer to store the generated Excel file
                const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});

                saveAs(blob, "Potenciales.xlsx");
            }
            setCargando(false)
        })
    }


    useEffect(() => {

        contarPropiedad({coleccion: 'potenciales', propiedad: 'estado', valor: 'Nuevo'}).then((dox) => {
            if (dox.res) {
                setNumProductos(dox.data)
            }
        })

        llenarValores()

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Potenciales'} textoComplementario={'usuario nuevos'}
                                      textoResaltado={`${numProductos} Pontenciales`}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>
                <Indicadores valores={valoresPedidos} dir={'flex-end'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'flex-end', marginTop: 2, marginBottom: -3}}>
                <LoadingButton variant={'contained'}
                               loading={cargando}
                               onClick={() => exportarDatos()}
                               endIcon={<CloudDownload/>}>Exportar datos</LoadingButton>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    estados={ESTADOSPOTENCIALES}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numProductos}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Potenciales

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'right',
        propiedad: 'estado',

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    }
]

const botonesTabla = [
    {
        nombre: 'Editar',
        icono: Edit,
        id: 'editar'
    },

]



