/**************************************************
 * Nombre:       Dialogo_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {GRADIENTE} from "../../Constantes";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import {useEffect, useState} from "react";
import Formulario_ver_ganador from "../Formularios/Formulario_ver_ganador";
import {obtenerColWheres} from "../../Servicios/BD/obtenerColWheres";
import {where} from "firebase/firestore";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";
import Pdf_Contrato from "../Pdfs/Pdf_Contrato";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {theme} from "../../Tema";
import Formulario_ver_participacion_ganadora from "../Formularios/Formulario_ver_participacion_ganadora";
import {obtenerCorreoDeId} from "../../Utilidades/obtenerCorreoDeId";


const Dialogo_ver_Ganador = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad, limpiarEntidad, setEntidad} = useFormulario({valoresDefecto: {}})
    const {props: propsGanador, setEntidad: setEntidadGanador} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})
    const [esCompleta, setEsComplenta] = useState(false)
    const [contrato, setContrato] = useState({})
    const [participacionGanadora, setParticipacionGanadora] = useState({})


    const descubirGanador = () => {

        abrirCargador('Descubriendo el ganador')
        let wheres = [where('idSubasta', '==', datos.idSubasta)]
        obtenerColWheres({col: 'contratos', wheres: wheres}).then((dox) => {
            if (dox.res && dox.data[0]) {
                buscarGanador(dox.data[0])

            }
        })

    }

    const buscarGanador = (contrato) => {

        let participaciones = datos.participaciones
        let indexBeneficiado = participaciones.findIndex((e) => e.valor === contrato.valorBeneficiado)
        let participacionBeneficiada = participaciones[indexBeneficiado]

        actualizarSubasta(contrato, participacionBeneficiada)

    }

    const actualizarSubasta = (contrato, participacion) => {

        let subasta = datos
        subasta.contrato = contrato


        actualizarDoc('subastas', subasta.id, {
            contratoGanador: contrato,
            partipacionGanadora: participacion,
            estado: 'Cerrada'
        }).then((dox) => {
            if (dox.res) {
                setContrato(contrato)
                alert('Beneficiario Rebelado con exito')
                marcarGanador(participacion)
            }
        })


    }

    const marcarGanador = (participacion) => {

        let idUsuario = participacion.idUsuario
        actualizarDoc('usuarios', idUsuario, {ganador: true}).then((dox) => {
            if (dox.res) {
                actualizarParticipacion(participacion)
            }
        })
    }


    const actualizarParticipacion = (participacion) => {

        let ticket = {

            hash: participacion.hashTicket,
            id: participacion.idTicket,
            valor: participacion.valor,

        }
        let wheres = [where('ticketsParticipando', 'array-contains', ticket)]

        obtenerColWheres({col: 'participaciones', wheres}).then((dox) => {
            if (dox.data && dox.data[0]) {
                let participacion = dox.data[0]
                actualizarDoc('participaciones', participacion.id, {ganadora: true}).then((des) => {
                    if (des.res) {
                        alert('Ganador descubierto')
                        realizado()
                        cerrarCargador()
                        cerrar()
                    }
                })
            }
        })
    }

    useEffect(() => {

        if (datos) {

            let participaciones = []
            if (datos.participaciones) {
                participaciones = datos.participaciones
            }
            let obj = datos
            obj.numParticipaciones = participaciones.length
            setEntidad(obj)


            if (parseInt(obj.numTickets)  === participaciones.length) {
                setEsComplenta(true)
            }


            if (datos.contratoGanador) {
                setContrato(datos.contratoGanador)
            }

            if (datos.partipacionGanadora) {
                setParticipacionGanadora(datos.partipacionGanadora)
                let parti = {
                    ...datos.partipacionGanadora,
                    correo: obtenerCorreoDeId(datos.partipacionGanadora.idUsuario),
                }
                setEntidadGanador(parti)
            }

        }

    }, [datos]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 2}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_ver_ganador props={props}/>
            </Grid>


            {contrato && contrato.id &&

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <PDFDownloadLink document={<Pdf_Contrato item={contrato}/>}
                                     fileName={"Certificado de participacion"}
                                     style={{width: '100%'}}>
                        <Button fullWidth  >
                            Certificado
                        </Button>
                    </PDFDownloadLink>
                </Grid>

            }


            {contrato.id &&
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                    <Formulario_ver_participacion_ganadora props={propsGanador}/>
                </Grid>
            }

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                {esCompleta && !contrato.id &&
                    <Button
                        onClick={() => descubirGanador()}
                        sx={{px: 8, pt: 1, pb: 1.1, background: GRADIENTE}}>Descubir el ganador</Button>
                }
            </Grid>


        </Grid>
    )

}
export default Dialogo_ver_Ganador