export const obtenerNumeroGanadorV2 = ({arr, entidad}) => {


    let numGanador = -1

    for (let i = 0; i < arr.length; i++) {
        let num = arr[i]
        if (num > -1 && num <= parseInt(entidad.numTickets)) {
            numGanador = num
            return numGanador
        }
    }

    return numGanador

}