/**************************************************
 * Nombre:       Tarjeta_Avatar
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {GRADIENTE} from "../../Constantes";

const Tarjeta_Avatar_Select = ({item}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{px: 0, py: 2, borderBottom: 1, borderColor: '#00000020'}}
            spacing={2}
        >
            <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'center'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{
                        background: GRADIENTE,
                        borderRadius: '26%',
                        border: 4,
                        borderColor: '#fff',
                        boxShadow: 8,

                    }}
                >
                    <img src={item.imgCuadrado} width={'100%'} height={'auto'} style={{borderRadius: '25%'}}/>
                </Grid>


            </Grid>

            <Grid item container lg={9} sm={9} xs={9} sx={{justifyContent: 'flex-start'}}>
                <Typography sx={{fontSize: 22, fontWeight: 600}}>{item.nombre}</Typography>
            </Grid>

        </Grid>
    )

}
export default Tarjeta_Avatar_Select