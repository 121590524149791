/**************************************************
 * Nombre:       Dialogo_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {GRADIENTE} from "../../Constantes";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_detalle_referenciado from "../Formularios/Formulario_detalle_referenciado";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";

const Dialogo_Referencia_Detalle = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})

    const entregarMedallas = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando cambios')
            let obj = entidad
            obj.estado = 'Entregado'

            guardarDoc('referencias', obj).then((dox) => {
                if (dox.res) {
                    actualizarNumMedallas(obj)
                }
            })

        })


    }

    const actualizarNumMedallas = (ent) => {

        let idUsuario = ent.referenciado

        obtenerDoc('usuarios', idUsuario).then((dox) => {
            if (dox.res) {
                console.log(dox.data)
                let numActual = dox.data.numMedallas ? parseInt(dox.data.numMedallas) : 0
                let numSubir = numActual + parseInt(ent.numMedallas)

                actualizarDoc('usuarios', idUsuario, {numMedallas: numSubir}).then((des) => {
                    if (des.res) {
                        alert('Medallas entregadas con exito')
                        realizado()
                        cerrar()
                    }
                    cerrarCargador()
                })
            }
        })

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 2}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_detalle_referenciado props={props} editable={datos && datos.estado !== 'Entregado'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    disabled={datos && datos.estado === 'Entregado'}
                    onClick={() => entregarMedallas()}
                    sx={{px: 8, pt: 1, pb: 1.1, background: GRADIENTE}}>Entregar y guardar</Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_Referencia_Detalle