/**************************************************
 * Nombre:       Pdf_Cotizacion
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Document, Font, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import f500 from './Recursos/Geologica-Bold.ttf'
import {formatoFecha} from "../../Utilidades/formatoFecha";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";


const Pdf_Contrato = ({item}) => {
    Font.register({family: '500', src: f500})


    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff',
            padding: 28
        },
        git: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        gitc: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
        },
        texto: {
            fontSize: 18,
            fontFamily: '500'
        },
        textoMed: {
            fontSize: 14,
            fontFamily: '500'
        }

    });

    const TituloTexto = ({titulo, texto}) => {
        return (
            <View style={styles.git}>
                <Text style={styles.textoMed}>{titulo} </Text>
                <Text style={styles.textoMed}>{texto}</Text>
            </View>
        )
    }


    return (
        <Document>
            <Page size={'letter'} style={styles.page}>
                <View style={styles.gitc}>

                    <View style={styles.git}>
                        <Text style={styles.texto}>Certificado de Subasta</Text>
                    </View>

                    <View style={{marginTop: 10}}/>

                    <TituloTexto titulo={'Fecha de Creacion de Subasta:'}
                                 texto={formatoFecha(item.fechaCreacion)}/>
                    <TituloTexto titulo={'Id subasta:'} texto={item.idSubasta}/>
                    <TituloTexto titulo={'Producto:'} texto={item.producto}/>
                    <TituloTexto titulo={'Serial:'} texto={item.serial}/>
                    <TituloTexto titulo={'Valor Comercial:'}
                                 texto={formatoMoneda(item.valorComercial)}/>


                    <View style={{marginTop: 10}}/>


                    <TituloTexto titulo={'Numero de participaciones subasta:'}
                                 texto={item.numeroTickets}/>
                    <TituloTexto titulo={'Numeros al azar:'}
                                 texto={JSON.stringify(item.numerosRandom)}/>
                    <TituloTexto titulo={'Numeros al validos:'} texto={JSON.stringify(item.valoresValidos)}/>
                    <TituloTexto titulo={'Valor Maximo del producto:'}
                                 texto={formatoMoneda(item.valorMaximo)}/>
                    <TituloTexto titulo={'Valor Minimo del producto:'}
                                 texto={formatoMoneda(item.valorMinimo)}/>
                    <TituloTexto titulo={'Valor Dream Free:'}
                                 texto={formatoMoneda(item.valorBeneficiado)}/>
                    <TituloTexto titulo={'Contrato en String:'}
                                 texto={''}/>
                    <TituloTexto titulo={''}
                                 texto={item.contratoString}/>

                    <TituloTexto titulo={'Hash Subasta:'} texto={item.hash}/>


                </View>

            </Page>
        </Document>
    )
}

export default Pdf_Contrato;
