/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import Formulario_tickets_crear from "../Formularios/Formulario_tickets_crear";
import {PAQUETES} from "../../Constantes";
import {adaptador_ticket} from "../Adaptadores/adaptador_ticket";
import {guardarMultiplesDocs} from "../../Servicios/BD/guardarMultiplesDocs";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";

const Dialogo_Potenciales = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Creando y asignando tickets')
            let arrTickets = crearTickets(entidad)
            guardarMultiplesDocs(arrTickets, 'tickets').then((dox) => {
                if (dox.res) {
                    let obj = entidad
                    obj.fecha = new Date().getTime()
                    obj.valor = PAQUETES[entidad.index].valor
                    obj.estado = 'Nuevo'
                    guardarDoc('ventasTickets', obj).then((des) => {
                        if (des.res) {
                            cerrarCargador()
                            alert('Tickets creados y asignados con exito')
                            realizado()
                            cerrar()
                        }
                    })
                }
            })

        })
    }

    const crearTickets = (entidad) => {

        let paqueteEscogido = PAQUETES[entidad.index]
        let numTickets = paqueteEscogido.cantidad

        let arr = []
        for (let i = 0; i < numTickets; i++) {
            let ticket = adaptador_ticket({entidad: entidad, index: i})
            arr.push(ticket)
        }

        return arr

    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_tickets_crear props={props} editable={datos && datos.id === undefined}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2, marginBottom: 2}}>
                <Button
                    disabled={datos && datos.id !== undefined}
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Crear Tickets y Cargar
                </Button>
            </Grid>

        </Grid>

    )

}
export default Dialogo_Potenciales