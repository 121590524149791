export const obtenerNumeroJugarV2 = () => {

    let arr = []

    for (let i = 0; i < 11; i++) {
        let num = Math.random() * 10000
        arr.push(num.toFixed(0))
    }

    return arr
}