/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    Inventory, Link,
    LocalActivity,
    LocalMall,
    LocalPolice,
    LocalShipping,
    Money,
    QrCode2,
    ShoppingCart
} from "@mui/icons-material";
import {useState} from "react";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";


const Formulario_subasta_producto = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoImagenCrop dato={'img'} {...props} ancho={1} alto={1}/>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={QrCode2} nombre={'Id de subasta'} dato={'idSubasta'}  {...props}
                                  requerido={'El id es requerido'}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory} nombre={'Nombre de producto'} dato={'nombre'}  {...props}
                                  requerido={'El nombre es requerido'}

                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory} nombre={'# de serie'} dato={'serie'}  {...props}
                                  requerido={'El numero de serie'}

                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={LocalMall} nombre={'Valor compra'} dato={'compra'}  {...props}
                                   requerido={'El valor de compra es requerido'}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descricion del producto'}
                                  dato={'descripcion'}  {...props}
                                  requerido={'La descripcion es requerida'} lineas={5}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Link} nombre={'Link de caracteristicas completas'} dato={'link'}  {...props}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoImagenCropLista dato={'imgs'} {...props}   />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_subasta_producto