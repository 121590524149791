/**************************************************
 * Nombre:       FilaTabla
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Collapse, Grid, TableCell, TableRow, Typography} from "@mui/material";
import {useState} from "react";
import {isArray} from "chart.js/helpers";

const FilaTablaCell = ({botonesTabla, click, row, datosCabecera, getContenido}) => {
    const [open, setOpen] = useState(false)



    return (
        <>
            <TableRow sx={{'& > *': {borderBottom: 0}}}
                      hover={true}
            >
                {datosCabecera.map((datosCabecera, index) => {

                    return (
                        <TableCell
                            key={`datw-${index}`}
                            align={datosCabecera.direccion}
                            onClick={() => setOpen(!open)}
                        >

                            {isArray(getContenido(datosCabecera, row)) ?

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                        <Typography sx={{fontSize: 10}} >{getContenido(datosCabecera, row)[0]}</Typography>
                                    </Grid>

                                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                        <Typography sx={{fontSize: 14}} >{getContenido(datosCabecera, row)[1]}</Typography>
                                    </Grid>

                                </Grid>

                                :

                                getContenido(datosCabecera, row)
                            }


                        </TableCell>
                    )
                })}


            </TableRow>

            <TableRow>
                <TableCell style={{paddingBottom: open ? 10 : 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit sx={{width: '100%'}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                        >

                            {botonesTabla.map((item, index) => {
                                return (

                                    <Button
                                        onClick={() => click(row, item.id)}
                                        startIcon={<item.icono/>} sx={{
                                        mt: 1, mr: 1, fontSize: 12
                                    }}>
                                        {item.nombre}
                                    </Button>

                                )
                            })}

                        </Grid>
                    </Collapse>

                </TableCell>
            </TableRow>

        </>

    )

}
export default FilaTablaCell