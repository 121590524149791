export const ESTADOSPOTENCIALES = ['Nuevo', 'Contactado', 'Archivado']
export const GRADIENTE = 'linear-gradient(90deg, #2626ED 0%, #7D50F5 100%)'

export const NIVELESUSUARIOS = ['Crack', 'Bishop', 'Masón']
export const TIPOTRANSACCION = ['Trans. Bancolombia', 'Trans. Davivienda', 'Trans. Nequi', 'Efectivo']

export const ESTADOVENTATICKETS = ['Nuevo', 'Entregado']

export const PAQUETES = [
    {
        index: 0,
        cantidad: 1,
        nombre: '1 ticket x $ 4.000',
        valor: 4000
    },
    {
        index: 1,
        cantidad: 5,
        nombre: '5 ticket x $ 16.000',
        valor: 16000
    },
    {
        index: 2,
        cantidad: 10,
        nombre: '10 ticket x $ 30.000',
        valor: 30000
    },
    {
        index: 3,
        cantidad: 25,
        nombre: '25 ticket x $ 75.000',
        valor: 75000
    },
    {
        index: 4,
        cantidad: 50,
        nombre: '50 ticket x $ 150.000',
        valor: 150000
    },
    {
        index: 5,
        cantidad: 100,
        nombre: '100 ticket x $ 29.0000',
        valor: 290000
    },
]