/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Divider, Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    Inventory,
    LocalActivity,
    LocalMall,
    LocalPolice,
    LocalShipping, MilitaryTech,
    Money,
    QrCode2,
    ShoppingCart
} from "@mui/icons-material";
import {useState} from "react";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";


const Formulario_detalle_referenciado = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={LocalMall} nombre={'Usuario Creado'} dato={'usuarioCreado'}  {...props}
                                   requerido={'El valor de compra es requerido'}
                                   editable={false}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={QrCode2} nombre={'Id usuario referencio'} dato={'referenciado'}  {...props}
                                  requerido={'El id es requerido'} editable={false}

                    />
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Divider sx={{width: '100%'}} />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Entregar Medallas</Typography>
                </Grid>



                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={MilitaryTech} nombre={'# medallas'} dato={'numMedallas'}  {...props}
                                  requerido={'El Numero de medallas es requerido'}

                    />
                </Grid>




            </Grid>
        </form>

    )

}
export default Formulario_detalle_referenciado