import {obtenerNumeroJugarV2} from "./Utilidades/obtenerNumeroJugarV2";
import {obtenerNumeroGanadorV2} from "./Utilidades/obtenerNumeroGanadorV2";
import {crearValoresSorteo} from "./Utilidades/crearValoresSorteo";
import {obtenerNumerosValidosV2} from "./Utilidades/obtenerNumerosValidosV2";
import {obtenerValoresContrato} from "./Utilidades/obtenerValoresContrato";
import {encriptarSHAV2} from "./Utilidades/encriptarSHAV2";

export const generarContratoV2 = (entidad) => {

    //crear valores a sorteo
    let valoresSorteo = crearValoresSorteo(entidad)
    console.log('valores sorteo', valoresSorteo)
    //obtenemos numeros a jugar -> array de 10 numeros entre el 0 - 10000
    let numerosJugar = obtenerNumeroJugarV2()
    console.log('numeros a jugar', numerosJugar)
    //obtner numeros validos
    let numerosValidos = obtenerNumerosValidosV2({arr: numerosJugar, entidad: entidad})
    console.log('numeros a jugar', numerosValidos)
    //obtenemos numero ganador
    let numeroGanador = obtenerNumeroGanadorV2({arr: numerosValidos, entidad: entidad})
    console.log('numero ganador', numeroGanador)
    //obtenermos valor ganador
    let valorGanador = valoresSorteo[numeroGanador]
    console.log('valor ganador', valorGanador)
    //obtenemos datos para contrato + valor ganador
    let valoresContrato = obtenerValoresContrato({
        entidad: entidad,
        valorBeneficiado: valorGanador,
        posicionValorBeneficiado: numeroGanador,
        numerosValidos: numerosValidos,
        numerosRandom: numerosJugar,

    })
    console.log('Valores del contrato', valoresContrato)
    //obtener string
    let valoresString = JSON.stringify(valoresContrato)
    //encriptamos
    let hash = encriptarSHAV2(valoresString)
    //retornamos datos de contrato y hash
    return {valoresContrato: valoresContrato, contratoString: valoresString, hash: hash, valoresPosibles: valoresSorteo}

}