/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoSelectFuncion from "../../Modulo_formularios/Componentes/IngresoSelectFuncion";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";

const Formulario_usuario_avatar = ({...props}) => {
    const [avatares, setAvatares] = useState([])


    const setearDato = (e) => {
        let index = avatares.findIndex((es) => es.nombre === e)
        let avatar = avatares[index]
        if (index >= 0) {
            props.props.setValue('imgCuadrado', avatar.imgCuadrado)
            props.props.setValue('imgRectangular', avatar.imgRectangular)
            props.props.setValue('id', avatar.id)
        }

    }


    useEffect(() => {
        obtenerCol('avatares').then((dox) => {
            if (dox.res) {
                setAvatares(dox.data)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresoSelectFuncion nombre={'Selecciona Avatar'} opciones={avatares.map((it) => it.nombre)}
                                          dato={'nombre'} {...props} setDato={setearDato}/>
                </Grid>

            </Grid>
        </form>

    )

}
export default Formulario_usuario_avatar