/**************************************************
 * Nombre:       Dialogo_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {GRADIENTE} from "../../Constantes";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useState} from "react";
import Formulario_aprobar_subasta from "../Formularios/Formulario_aprobar_subasta";
import {Gavel} from "@mui/icons-material";
import {generarContratoV2} from "../ContratoV2/generarContratoV2";
import {adaptador_contrato_publico} from "../Adaptadores/adaptador_contrato_publico";

const Dialogo_aprobar_subasta = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})
    const [sugerencia, setSugerencia] = useState(0)

    const guardarDatos = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando cambios')
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.estado = 'Activa'
            obj.participaciones = []
            // crear contrato
            //subirSubasta(obj)
            let contrato = generarContratoV2(entidad)

            //se coloca seguridad
            obj.contratoString = JSON.stringify(adaptador_contrato_publico(contrato.valoresContrato))
            obj.valoresContrato = adaptador_contrato_publico(contrato.valoresContrato)
            obj.valoresPosibles = contrato.valoresPosibles
            obj.hash = contrato.hash

            console.log('entidad completa', obj)

            if (contrato.valoresContrato.posicionValorBeneficiado < 0) {
                cerrarCargador()
                alert('Valor Beneficiado no valido, intentelo nuevamente')
                return
            }

            subirSubasta(obj, contrato)

        })
    }


    const subirSubasta = (ent, contrato) => {
        let obj = ent

        subirImagenFile(ent.img, 'productos').then((dox) => {

            if (dox.res) {
                obj.img = dox.data

                subirVariasImagenFile(ent.imgs, 'productos').then((des) => {
                    if (des.res) {
                        obj.imgs = des.data


                        guardarDoc('subastas', obj).then((des) => {
                            if (des.res) {
                                let id = des.data

                                subirContrato(id, contrato)

                            }
                        })

                    }
                })

            }
        })


    }

    const subirContrato = (id, contrato) => {


        let obj = {
            id: id,
            ...contrato.valoresContrato,
            contratoString: contrato.contratoString,
            hash: contrato.hash
        }

        guardarDoc('contratos', obj).then((dox) => {
            if (dox.res) {
                cerrar()
                cerrarCargador()
                realizado()
                alert('Contrato realizado con exito, la subasta ahora esta activa')
            }
        })

    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 2}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_aprobar_subasta props={props} sugerencia={sugerencia}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4, marginBottom: 2}}>
                <Button
                    disabled={datos.estado !== 'Inactiva'}
                    startIcon={<Gavel/>}
                    onClick={() => guardarDatos()}
                    sx={{px: 8, pt: 1, pb: 1.1, background: GRADIENTE}}>Aprobar y Subastar</Button>
            </Grid>


        </Grid>
    )

}
export default Dialogo_aprobar_subasta