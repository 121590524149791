/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import Formulario_tickets_crear from "../Formularios/Formulario_tickets_crear";
import {PAQUETES} from "../../Constantes";
import {adaptador_ticket} from "../Adaptadores/adaptador_ticket";
import {guardarMultiplesDocs} from "../../Servicios/BD/guardarMultiplesDocs";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_tickets_detalle from "../Formularios/Formulario_tickets_detalle";
import Formulario_usuario from "../Formularios/Formulario_usuario";
import Formulario_avatar from "../Formularios/Formulario_avatar";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";

const Dialogo_avatar_detalle = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos ? datos : {tipo: 'Privado'}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo imagenes')
            let obj = entidad
            obj.fecha = new Date().getTime()
            subirImagenFile(entidad.imgRectangular, 'avatares').then((dox) => {
                if (dox.res) {
                    obj.imgRectangular = dox.data

                    subirImagenFile(entidad.imgCuadrado, 'avatares').then((des) => {
                        if (des.res) {
                            obj.imgCuadrado = des.data
                            subirAvatar(obj)
                        }
                    })

                }
            })


        })
    }

    const subirAvatar = (obj) => {
        guardarDoc('avatares', obj).then((dox) => {
            if (dox.res) {
                realizado()
                cerrar()
                cerrarCargador()
                alert('Cambios realizados con exito')
            } else {
                cerrarCargador()
                alert('No se realizaron los cambios, por favor intentelo nuevamente')
            }
        })
    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginBottom: 4}}>
                <Formulario_avatar props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginBottom: 2}}>
                <Button onClick={() => guardarCambios()}>Guardar cambios</Button>
            </Grid>

        </Grid>

    )

}
export default Dialogo_avatar_detalle