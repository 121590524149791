import {encriptarSHAV2} from "../ContratoV2/Utilidades/encriptarSHAV2";

export const adaptador_ticket = ({entidad, index}) => {

    let datos = {
        id: new Date().getTime() +  'TIK' + index,
        tipo: entidad.tipo,
        codigoTransferencia: entidad.codigo,
        fecha: new Date().getTime(),
        paquete: entidad.paquete,
        ordenCreacion: index,
        idUsuario: entidad.idUsuario,

    }

    let datosString = JSON.stringify(datos)
    let hash = encriptarSHAV2(datosString)

    let ticket = {
        ...datos,
        datosString: datosString,
        hash: hash,
        idSubasta: 'none',
        nick: entidad.nick
    }

    return ticket


}