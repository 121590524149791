export const obtenerValoresContrato = ({
                                           entidad,
                                           numerosRandom,
                                           numerosValidos,
                                           posicionValorBeneficiado,
                                           valorBeneficiado,
                                       }) => {

    let valores = {
        idSubasta: entidad.idSubasta,
        nombre: entidad.nombre.replaceAll(' ', '_'),
        serial: entidad.serie.replaceAll(' ', '_'),
        numeroTickets: entidad.numTickets,
        valorComercial: entidad.compra,
        valorMinimo: entidad.minimo,
        valorMaximo: entidad.maximo,
        fechaCreacion: entidad.fecha,
        valoresValidos: numerosValidos,
        posicionValorBeneficiado: posicionValorBeneficiado,
        valorBeneficiado: valorBeneficiado,
        numerosRandom: numerosRandom

    }

    return valores
}