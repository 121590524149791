/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOVENTATICKETS} from "../../Constantes";
import {LocalActivity, ManageSearch, Receipt} from "@mui/icons-material";
import {where} from "firebase/firestore";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import Dialogo_Tickets_Crear from "../Dialogos/Dialogo_Tickets_Crear";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {obtenerFechaHoyPrimeraHora} from "../../Utilidades/obtenerFechaHoyPrimeraHora";
import {contarPropiedadSumaWheres} from "../../Servicios/BD/Contadores/contarPropiedadSumaWheres";

const Seccion_Venta_Tickets = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numVentasHoy, setNumVentasHoy] = useState(0)
    const [numVentas, setNumVentas] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'ventasTickets',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Tickets_Crear,
        realizado: () => setRecargar(!recargar),
        titulo: 'Creando Tickets'
    })


    const BotonesTitulo = [
        <Button
            startIcon={<Receipt/>}
            onClick={() => abrir()}
        >
            Crear tickets
        </Button>
    ]

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'detalles' :
                abrir(row)
                break

        }

    }


    useEffect(() => {

        let hoy = obtenerFechaHoyPrimeraHora()
        let wheres = [where('fecha', '>', hoy.getTime())]
        contarPropiedadSumaWheres({coleccion: 'ventasTickets', wheres: wheres, propiedad: 'valor'}).then((dox) => {
            console.log(dox)
            if (dox.res) {
                setNumVentasHoy(dox.data)
            }
        })

        contarColeccion({coleccion: 'ventasTickets', wheres}).then((dox) => {
            if (dox.res) {
                setNumVentas(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Venta Tickets'} textoComplementario={'vendido hoy'}
                                      textoResaltado={`${formatoMoneda(numVentasHoy)}`} botones={BotonesTitulo}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    estados={ESTADOVENTATICKETS}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numVentas}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Venta_Tickets

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Paquete',
        direccion: 'left',
        propiedad: 'paquete',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Nick Usuario',
        direccion: 'left',
        propiedad: 'nick',

    },
    {
        nombre: 'Tipo',
        direccion: 'left',
        propiedad: 'tipo',

    },

    {
        nombre: 'Valor',
        direccion: 'right',
        propiedad: 'valor',
        tipo: 'moneda'

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Paquete',
        direccion: 'left',
        propiedad: 'paquete',
        tipo: 'fechaDato'

    },
    {
        nombre: 'Nick Usuario',
        direccion: 'right',
        propiedad: 'nick',

    }
]

const botonesTabla = [
    {
        nombre: 'Detalles',
        icono: ManageSearch,
        id: 'detalles'
    }

]



