/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import Formulario_tickets_crear from "../Formularios/Formulario_tickets_crear";
import {PAQUETES} from "../../Constantes";
import {adaptador_ticket} from "../Adaptadores/adaptador_ticket";
import {guardarMultiplesDocs} from "../../Servicios/BD/guardarMultiplesDocs";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_tickets_detalle from "../Formularios/Formulario_tickets_detalle";
import Formulario_usuario from "../Formularios/Formulario_usuario";

const Dialogo_usuario_detalle = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Creando y asignando tickets')
            let obj = entidad
            guardarDoc('usuarios', obj).then((dox) => {
                if (dox.res) {
                    realizado()
                    cerrar()
                    cerrarCargador()
                    alert('Usuario actualizado')
                } else {
                    cerrarCargador()
                    alert('No se realizaron los cambios, por favor intentelo nuevamente')
                }
            })

        })
    }


    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginBottom: 4}}>
                <Formulario_usuario props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginBottom: 2}}>
                <Button onClick={() => guardarCambios()}>Actualizar Datos</Button>
            </Grid>

        </Grid>

    )

}
export default Dialogo_usuario_detalle