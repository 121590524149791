/**************************************************
 * Nombre:       IngresoTexto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Fab, Grid, InputAdornment, TextField} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import {findAllByAltText} from "@testing-library/react";
import {timeout} from "workbox-core/_private";
import login from "../../Login/Login";


const IngresoSelectComponente = ({
                                     props,
                                     dato,
                                     editable = true,
                                     Componente,
                                     datoInput,
                                     coleccion,
                                     datoDos=null,
                                     datoInputDos

                                 }) => {
    const {getValues, setValue, watch} = props
    const [arrObjeto, setArrObjeto] = useState([])
    const refe = useRef(null)

    const ingresarDato = (index) => {
        let escogido = arrObjeto[index]
        if (escogido) {
            setValue(dato, escogido[datoInput])
            if (datoDos){
                setValue(datoDos, escogido[datoInputDos])
            }
        }

    }

    const buscarActual = () => {
        let obj = getValues()


        if (obj[dato]) {
            let index = arrObjeto.findIndex((e) => e[datoInput] === obj[dato])
            if (index >= 0 && refe.current) {
                refe.current.go(index)
            }
        }

    }

    useEffect(() => {
        let obj = getValues()

        if (obj[coleccion]) {
            let objs = obj[coleccion]
            setArrObjeto(objs)

        } else {
            setArrObjeto([])
        }


    }, [watch(coleccion, dato)]);

    useEffect(() => {

        if (refe.current) {
            let splide = refe.current.splide
            splide.on('move', (index) => {
                ingresarDato(index)
            })

        }

    }, [refe.current]);


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            onLoad={() => buscarActual()}
        >
            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Splide
                    ref={refe}
                    aria-label="My Favorite Images Two" style={{width: '100%'}}
                    options={{
                        autoplay: false,
                        speed: 3000,
                        rewind: true,
                        perPage: 1,
                        arrows: true,
                        gap: 20,
                        pagination: true,
                        drag: 'free',
                        width: '100%'
                    }}
                >
                    {arrObjeto.map((item, index) => {
                        return (
                            <SplideSlide key={`tr${index}`}>
                                <Componente item={item}/>
                            </SplideSlide>
                        )
                    })}


                </Splide>

            </Grid>


        </Grid>

    )

}
export default IngresoSelectComponente


