export const obtenerNumerosValidosV2 = ({arr, entidad}) => {

    // numero de cifras en numTickets
    // quitamos las cifras del puesto 0 del arr para que coincidan con numero cifras numTicket
    // quitamos el mismo numde cifras de todos los demas elementos del arr
    // realizamos la busqueda

    let numTickets = entidad.numTickets
    let numCifras = numTickets.toString().length
    let numCifrasPrimerValor = arr[0].toString().length
    let diferenciaCifras = numCifrasPrimerValor - numCifras

    let restar = 0
    if (diferenciaCifras > 0) {
        restar = diferenciaCifras
    }

    let numerosJugadores = []

    for (let i = 0; i < arr.length; i++) {
        let numArr = arr[i]
        let numString = numArr.toString()
        let numeroTransformado = numString.substring(0, numString.length - restar)
        numerosJugadores.push(parseInt(numeroTransformado))

    }

    return numerosJugadores
}