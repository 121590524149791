/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Divider, Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Inventory, LocalMall, QrCode2} from "@mui/icons-material";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import IngresoFecha from "../../Modulo_formularios/Componentes/IngresoFecha";


const Formulario_ver_participacion_ganadora = ({...props}) => {


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
            >


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={QrCode2} nombre={'Nick Usuario'} dato={'nick'}  {...props}
                                  requerido={'El id es requerido'} editable={false}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={LocalMall} nombre={'Correo'} dato={'correo'}  {...props}
                                   requerido={'El valor de compra es requerido'}
                                   editable={false}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Divider sx={{width: '100%'}}/>
                </Grid>


                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoFecha Icono={Inventory} nombre={'Fecha de Participacion'} dato={'fecha'}  {...props}
                                  requerido={'El nombre es requerido'}
                                  editable={false}
                    />
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={LocalMall} nombre={'Valor Propuesto'} dato={'valor'}  {...props}
                                   requerido={'El valor de compra es requerido'}
                                   editable={false}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory} nombre={'idTicket'} dato={'idTicket'}  {...props}
                                  requerido={'El nombre es requerido'}
                                  editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory} nombre={'Codigo Transferencia'}
                                  dato={'codigoTransferencia'}  {...props}
                                  requerido={'El nombre es requerido'}
                                  editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory} nombre={'Hash Ticket'} dato={'hashTicket'}  {...props}
                                  requerido={'El nombre es requerido'}
                                  editable={false}
                    />
                </Grid>





            </Grid>
        </form>

    )

}
export default Formulario_ver_participacion_ganadora