/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {ESTADOVENTATICKETS} from "../../Constantes";
import {AddReaction, EmojiEmotions, ManageSearch, Receipt} from "@mui/icons-material";
import {where} from "firebase/firestore";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";
import {contarColeccionWhere} from "../../Servicios/BD/Contadores/contarColeccionWhere";
import {useObtenerColleccionTablaWhere} from "../../Servicios/BD/useObtenerColleccionTablaWhere";
import Dialogo_usuario_detalle from "../Dialogos/Dialogo_usuario_detalle";
import Dialogo_usuario_avatar from "../Dialogos/Dialogo_usuario_avatar";

const Seccion_Usuarios = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numUsuarios, setNumUsuarios] = useState(0)
    const [numTickets, setNumTickets] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const [wheres, setWheres] = useState([where('tipo', '!=', 'Admin')])
    const {props, cargarNuevamente} = useObtenerColleccionTablaWhere({
        coleccion: 'usuarios',
        filtroInicial: 'nick',
        dirInicial: 'desc',
        wheres: wheres
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_usuario_detalle,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle del Usuario'
    })
    const {Dialogo: DialogoAvatar, abrir: abrirAvatar} = useDialogo({
        Componente: Dialogo_usuario_avatar,
        realizado: () => setRecargar(!recargar),
        titulo: 'Añadir Avatares'
    })


    const BotonesTitulo = []

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'detalles' :
                abrir(row)
                break
            case 'avatares' :
                abrirAvatar(row)
                break

        }

    }


    useEffect(() => {


        let wheres = [where('tipo', '!=', 'Admin')]
        contarColeccionWhere({coleccion: 'usuarios', wheres: wheres}).then((dox) => {
            if (dox.res) {
                setNumUsuarios(dox.data)
            }
        })

        contarColeccion({coleccion: 'tickets', wheres}).then((dox) => {
            if (dox.res) {
                setNumTickets(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <DialogoAvatar/>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Usuarios'} textoComplementario={''}
                                      textoResaltado={`${numUsuarios} usuarios regitrados`} botones={BotonesTitulo}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numTickets}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Usuarios

const filtros = [
    {
        nombre: 'Nickname',
        propiedad: 'nick',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Nickname',
        direccion: 'left',
        propiedad: 'nick',


    },
    {
        nombre: 'Tipo',
        direccion: 'left',
        propiedad: 'tipo',
    },
    {
        nombre: 'Medallas',
        direccion: 'left',
        propiedad: 'medallas',
    }

]


const datosCabeceraCell = [
    {
        nombre: 'Nickname',
        direccion: 'left',
        propiedad: 'nick',


    },
    {
        nombre: 'Tipo',
        direccion: 'left',
        propiedad: 'tipo',
    },
    {
        nombre: 'Medallas',
        direccion: 'left',
        propiedad: 'medallas',
    }
]

const botonesTabla = [
    {
        nombre: 'Detalles',
        icono: ManageSearch,
        id: 'detalles'
    },
    {
        nombre: 'Avatares',
        icono: AddReaction,
        id: 'avatares'
    },

]



