/**************************************************
 * Nombre:       Dialogo_Registro
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {GRADIENTE} from "../../Constantes";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_color.svg'
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import Formulario_Subasta from "../Formularios/Formulario_Subasta";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {subirVariasImagenFile} from "../../Servicios/Storage/subirVariasImagenFile";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {generarContrato} from "../ContratoInteligente/generarContrato";
import Formulario_subasta_producto from "../Formularios/Formulario_subasta_producto";
import {Delete} from "@mui/icons-material";
import {borrarDoc} from "../../Servicios/BD/borrarDoc";

const Dialogo_producto_subasta = ({cerrar, realizado, datos}) => {
    const {props, obtenerEntidad, limpiarEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})

    const guardarDatos = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando cambios')
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.estado = 'Inactiva'

            if (obj.id) {
                subirSubasta(obj)
            } else {
                consultarIdSubasta(obj)
            }

        })
    }

    const consultarIdSubasta = (ent) => {
        let q = query(collection(fire, 'subastas'), where('idSubasta', '==', ent.idSubasta))
        obtenerColeccionQuery(q).then((dox) => {
            if (dox.res && dox.data.length > 0) {
                alert('Este Id de Subasta ya existe')
                cerrarCargador()
            } else {
                subirSubasta(ent)
            }
        })
    }


    const subirSubasta = (ent) => {
        let obj = ent

        subirImagenFile(ent.img, 'productos').then((dox) => {

            if (dox.res) {
                obj.img = dox.data

                subirVariasImagenFile(ent.imgs, 'productos').then((des) => {
                    if (des.res) {
                        obj.imgs = des.data


                        guardarDoc('subastas', obj).then((des) => {
                            if (des.res) {
                                cerrar()
                                cerrarCargador()
                                realizado()
                                alert('Cambios realizados con exito')
                            }
                        })

                    }
                })

            }
        })


    }

    const borrar = () => {
        abrirCargador('Borrando Coleccion')
        borrarDoc('subastas', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{px: 2}}
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Formulario_subasta_producto props={props}
                                             editable={datos && datos.estado === 'Inactiva'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => guardarDatos()}
                    sx={{px: 8, pt: 1, pb: 1.1, background: GRADIENTE}}>Guardar Cambios</Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id && datos.estado === 'Inactiva' &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar subasta</Button>
                }
            </Grid>


        </Grid>
    )

}
export default Dialogo_producto_subasta